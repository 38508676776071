import React from "react"
import styled from "styled-components"
import classList from "../../services/classList"
import { useState, useEffect } from "react"
const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const SingleStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(33, 33, 33, 0.6);

  &.active,
  &.filled {
    color: #000;
    .step-name {
      cursor: pointer;
    }
    .line {
      background-color: #000;
    }
  }
  &.active {
    font-weight: bold;
  }
  &:first-of-type {
    .line {
      display: none;
    }
  }
`
const LeftLineComponent = styled.div`
  min-width: 80px;
  height: 1px;
  background-color: rgba(33, 33, 33, 0.2);
  margin: 0 10px;
`

export default function BookingStepsComponent({ onItemClick, stepsConfig }) {
  const [steps, setSteps] = useState(null)
  useEffect(() => {
    setSteps(stepsConfig)
  }, [stepsConfig])
  return (
    <StepsWrapper>
      {steps &&
        steps
          .filter((s, i) => !s.hide)
          .map((s, i) => {
            return (
              <SingleStep
                key={i}
                className={classList({
                  active: s.active,
                  filled: s.filled,
                  hide: s.hide,
                })}
              >
                <LeftLineComponent className="line"></LeftLineComponent>
                <div
                  onClick={() => s.filled && onItemClick(s)}
                  className="step-name"
                >
                  {s.name}
                </div>
              </SingleStep>
            )
          })}
    </StepsWrapper>
  )
}
