import React from "react"
import styled from "styled-components"
import DefaultLoader from "../shared/DefaultLoaderComponent"
// import { RouteComponentProps } from "@reach/router"

const BookingWrapper = styled.div`
  padding-top: 120px;
`

export default function mainBookingComponent(props) {
  return (
    <BookingWrapper>
      <DefaultLoader />
      {props.id}
    </BookingWrapper>
  )
}
