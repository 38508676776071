import React, { useState, useEffect } from "react"
import moment from "moment"
import styled from "styled-components"
import chevronIcon from "../../../Images/icons/chevron.svg"
import classList from "../../../services/classList"
import CurrentDayAvailableHours from "./CurrentDayAvailableHours"
import { getReservations } from "../../../services/api"
import DefaultLoader from "../../shared/DefaultLoaderComponent"

function getMockedDay(date) {
  return getReservations(date)
}

const WeekChooserWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
`
const PrevWeekIconWrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    transform: rotate(180deg);
    height: 15px;
    margin: 0;
    cursor: pointer;
  }
`
const WeekDates = styled.div`
  margin: 0 10px;
  width: 140px;
  text-align: center;
  span {
    margin: 0 5px;
  }
`

const NextWeekIconWrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    height: 15px;
    cursor: pointer;
    margin: 0;
  }
`

const DaysWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const DayPointerWrapper = styled.div`
  border: 2px solid rgba(33, 33, 33, 0.6);
  /* border-radius: 50%; */
  margin: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  cursor: pointer;
  &.current-day {
    border: 2px solid #000;
    font-weight: bold;
  }
  &:hover,
  &.active-day {
    background-color: #000;
    color: #fff;
    font-weight: bold;
  }
`

export default function DateChooseComponent({ product }) {
  const [allFetchedDays, setFetchedDays] = useState(null)
  const [currentActiveDate, setCurrentActiveDate] = useState(new Date())
  const [currentActiveDays, setCurrentActiveDays] = useState(null)
  const [currentActiveDayConfig, setCurrentActiveDayConfig] = useState(null)

  function prepareCurrentWeekDays() {
    const startOfWeek = moment(currentActiveDate).startOf("isoWeek")
    const endOfWeek = moment(currentActiveDate).endOf("isoWeek")
    const days = []
    let day = startOfWeek

    while (day <= endOfWeek) {
      days.push(day.toDate())
      day = day.clone().add(1, "d")
    }
    return days
  }

  useEffect(() => {
    setCurrentActiveDays(
      prepareCurrentWeekDays().map(e => {
        return { date: e }
      })
    )
    getMockedDay(currentActiveDate).then(res => {
      setCurrentActiveDayConfig(res.data)
    })
  }, [currentActiveDate])

  function onPrevWeekIconClick() {
    setCurrentActiveDate(moment(currentActiveDate).add(-7, "days").toDate())
  }
  function onNextWeekIconClick() {
    setCurrentActiveDate(moment(currentActiveDate).add(7, "days").toDate())
  }

  function onDayClick(day) {
    setCurrentActiveDate(day.date)
  }
  return (
    <div>
      {currentActiveDays && (
        <>
          <WeekChooserWrapper>
            <PrevWeekIconWrapper>
              <img
                onClick={onPrevWeekIconClick}
                className="icon"
                src={chevronIcon}
              ></img>
            </PrevWeekIconWrapper>
            <WeekDates>
              <span> {moment(currentActiveDays[0].date).format("DD.MM")}</span>-
              <span>
                {moment(
                  currentActiveDays[currentActiveDays.length - 1].date
                ).format("DD.MM")}
              </span>
            </WeekDates>
            <NextWeekIconWrapper>
              <img
                onClick={onNextWeekIconClick}
                className="icon"
                src={chevronIcon}
              ></img>
            </NextWeekIconWrapper>
          </WeekChooserWrapper>

          <DaysWrapper>
            {currentActiveDays.map((e, i) => {
              return (
                <DayPointerWrapper
                  onClick={() => onDayClick(e)}
                  className={classList({
                    "current-day": moment(e.date).isSame(new Date(), "day"),
                    "active-day": moment(e.date).isSame(
                      currentActiveDate,
                      "day"
                    ),
                  })}
                  key={i}
                >
                  {moment(e.date).format("DD")}
                </DayPointerWrapper>
              )
            })}
          </DaysWrapper>
          {currentActiveDayConfig ? (
            <CurrentDayAvailableHours
              product={product}
              config={currentActiveDayConfig}
            ></CurrentDayAvailableHours>
          ) : (
            <DefaultLoader />
          )}
        </>
      )}
    </div>
  )
}
