import React, { useState, useEffect } from "react"
import styled from "styled-components"
import MainButton from "../../shared/MainButtonComponent"
import DefaultLoaderComponent from "../../shared/DefaultLoaderComponent"
import { sendRequest } from "../../../services/requestTool"
import noPhotoImage from "../../../Images/icons/no-photo.svg"

const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 5px;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`
const ProductImage = styled.img`
  width: 40px;
  margin: 0;
  padding: 0;
`
const ProductDetails = styled.div`
  flex: 4;
`
const ProductHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const ProductName = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: 600;
  margin-right: 5px;
`
const ProductDuration = styled.span`
  color: rgba(33, 33, 33, 0.6);
  font-size: 12px;
`
const ProductDescription = styled.div`
  color: rgba(66, 66, 66, 0.6);
  font-size: 13px;
`

const ProductPrice = styled.div`
  flex: 1;
  white-space: nowrap;
  margin: 0 5px;
  font-size: 14px;
  text-align: right;
  @media all and (min-width: 576px) {
    padding-right: 10px;
  }
`
const ProductsBookingSection = styled.div`
  flex: 1;
  margin: 0;
`
export default function ProductListItem({
  showProductImage,
  config,
  productBookingCallback,
}) {
  const [currentWidth, setCurrentWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  )
  const [showProductPhotoBreakpoint, setShowProductPhotoBreakpoint] = useState(
    568
  )
  const [productImages, setProductsImages] = useState(null)
  const getFile = function (fileID) {
    return sendRequest({
      url: `https://spoomyfly.com/api/File/GetFile?FileID=${fileID}`,
      responseType: "blob",
    })
  }
  function prepareProductsImages(promiseValues) {
    const images = promiseValues
      .filter(e => {
        if (e.status == "fulfilled") {
          return e
        }
      })
      .map(
        e =>
          typeof window !== "undefined" &&
          window.URL.createObjectURL(new Blob([e.value.data]))
      )
    if (!images[0]) {
      images.push(noPhotoImage)
    }
    return images
  }
  useEffect(() => {
    typeof window !== "undefined" &&
      window.addEventListener("resize", () => {
        setCurrentWidth(window.innerWidth)
      })
    const allPromises = config.fileIds.map(e => {
      return getFile(e)
    })
    Promise.allSettled(allPromises).then(values => {
      setProductsImages(prepareProductsImages(values))
    })
  }, [])
  return (
    <ListItemWrapper>
      {showProductImage && currentWidth > showProductPhotoBreakpoint && (
        <ImageWrapper>
          {productImages ? (
            <ProductImage src={productImages[0]} />
          ) : (
            <DefaultLoaderComponent />
          )}
        </ImageWrapper>
      )}
      <ProductDetails>
        <ProductHeader>
          <ProductName>{config.name}</ProductName>
          <ProductDuration>
            {config.serviceTimeInterval.hours * 60 +
              config.serviceTimeInterval.minutes}{" "}
            min
          </ProductDuration>
        </ProductHeader>
        <ProductDescription>{config.description}</ProductDescription>
        {
          //TODO: przy dlugich opisach uslug wyswietlic tylko poczatek i .... lub klik szczegoly i collapse/accordion https://www.npmjs.com/package/rc-collapse
        }
      </ProductDetails>
      <ProductPrice>{config.servicePrice} PLN</ProductPrice>
      <ProductsBookingSection>
        <MainButton
          active={config.selected}
          width="80px"
          margin="0"
          padding="5px"
          fontSize="12px"
          borderThickness="2"
          text={config.selected ? "wybrano" : "wybierz"}
          onClick={() => productBookingCallback(config)}
        />
      </ProductsBookingSection>
    </ListItemWrapper>
  )
}
