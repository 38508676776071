import React, { useEffect, useState } from "react"
import { getServices } from "../../../services/api"
import styled from "styled-components"
import DefaultLoaderComponent from "../../shared/DefaultLoaderComponent"
import ProductListItem from "./ProductListItem"

const ProductsWrapper = styled.div``
const ProductsList = styled.div`
  max-width: 738px;
  margin: 0 auto;
  & > div {
    border-bottom: 1px solid rgba(133, 133, 133, 0.6);
    &:last-of-type {
      border: none;
    }
  }
`

export default function ChooseProductComponent({
  onProductsChangeState,
  allowMultiselect,
  onProductsDownload,
  availableProducts,
}) {
  const [products, setProducts] = useState(null)
  const [errorDuringDataDownload, setDownloadError] = useState(false)

  useEffect(() => {
    console.log(availableProducts)
    if (availableProducts) {
      setProducts(availableProducts)
      return
    }
    getServices()
      .then(res => {
        if (!res.data) throw "err"
        onProductsDownload(res.data)
        setProducts(
          res.data.map((e, i) => {
            e.productId = i
            e.selected = false
            return e
          })
        )
      })
      .catch(err => {
        setDownloadError(true)
      })
  }, [])

  const onProductBookingCallback = e => {
    console.log("OUTPUT ~ file: ChooseProductComponent.js ~ line 43 ~ e", e)
    const newState = products.map(p => {
      if (!allowMultiselect) p.selected = false
      if (p.productId == e.productId) {
        p.selected = !p.selected
      }
      return p
    })
    setProducts(newState)
    onProductsChangeState(newState)
  }
  return (
    <ProductsWrapper>
      {products ? (
        <ProductsList>
          {products.map((p, pI) => {
            return (
              <ProductListItem
                showProductImage={true}
                productBookingCallback={onProductBookingCallback}
                key={pI}
                config={p}
              />
            )
          })}
        </ProductsList>
      ) : errorDuringDataDownload ? (
        <div>error during data download</div>
      ) : (
        <DefaultLoaderComponent />
      )}
    </ProductsWrapper>
  )
}
