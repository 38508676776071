import React, { useEffect, useState } from "react"
import Moment from "moment"
import { extendMoment } from "moment-range"
import styled from "styled-components"

const moment = extendMoment(Moment)

const AvailableReservationsWrapper = styled.div`
  display: flex;
  max-width: 992px;
  margin: 0 auto;
`

const DayPartWrapper = styled.div`
  flex: 1;
`
const DayPartName = styled.div`
  text-align: center;
  font-size: 14px;
  @media all and (min-width: 480px) {
    font-size: 18px;
    margin: 10px 0 5px 0;
  }
`

const DayPartHours = styled.div``
const SingleHourWrapper = styled.div`
  border: 1px solid rgba(133, 133, 133, 1);
  margin: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease;
  font-size: 12px;
  padding: 8px 0;

  &:hover,
  &.active {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
    font-weight: bold;
  }
  @media all and (min-width: 480px) {
    font-size: 14px;
  }
`

export default function CurrentDayAvailableHours({ config, product }) {
  const [productMinuteInterval, setProductMinuteInterval] = useState(null)
  const [suggestedAvailableDates, setSuggestedAvailableDates] = useState(null)

  const [dayBreakpoints, setDayBreakpoints] = useState([
    {
      order: 3,
      key: "evening",
      breakpoint: 16,
      text: "Po południu",
    },
    {
      order: 2,
      key: "noon",
      breakpoint: 12,
      text: "Południe",
    },
    {
      order: 1,
      key: "morning",
      breakpoint: 0,
      text: "Rano",
    },
  ])

  const [
    defaultReservationsInterval,
    setDefaultReservationsInterval,
  ] = useState(15)

  useEffect(() => {
    console.log("product", product)
    const interval =
      config.reservations[0].workPoint.workersInfo[0].services[0].interval
    const minutes = interval.hours * 60 + interval.minutes
    setProductMinuteInterval(45)
  }, [config])

  useEffect(() => {
    if (!config || !productMinuteInterval) return
    const startDate = new Date(config.date)
    startDate.setHours(config.reservations[0].workPoint.openingHours.hours)
    startDate.setMinutes(config.reservations[0].workPoint.openingHours.minutes)

    const endDate = new Date(config.date)
    endDate.setHours(config.reservations[0].workPoint.closingHours.hours)
    endDate.setMinutes(config.reservations[0].workPoint.closingHours.minutes)

    const suggestedDates = {}
    const busyTimeRanges =
      config.reservations[0].workPoint.workersInfo[0].busyTimeRanges

    let singleReservationTimeFrom = moment(startDate).add(
      "minutes",
      -defaultReservationsInterval
    )
    let singleReservationTimeTo = moment(
      new Date(singleReservationTimeFrom.toDate())
    )
    singleReservationTimeTo.add("minutes", productMinuteInterval)

    while (
      singleReservationTimeTo
        .add(defaultReservationsInterval, "m")
        .isSameOrBefore(endDate, "minutes")
    ) {
      singleReservationTimeFrom.add("minutes", defaultReservationsInterval)
      const start = new Date(singleReservationTimeFrom)
      const end = singleReservationTimeTo.toDate()

      const suggestedReservationRange = moment.range(start, end)

      const intersectedBusyTimeRange = busyTimeRanges
        .map(e => {
          return {
            startTime: moment(new Date(startDate))
              .set("hours", e.startTime.hours)
              .set("minutes", e.startTime.minutes),
            finishTime: moment(new Date(startDate))
              .set("hours", e.finishTime.hours)
              .set("minutes", e.finishTime.minutes),
          }
        })
        .find(e => {
          const busyTimeRange = moment.range(e.startTime, e.finishTime)
          return busyTimeRange.intersect(suggestedReservationRange)
        })
      if (intersectedBusyTimeRange) {
        const tmpStart = new Date(intersectedBusyTimeRange.finishTime.toDate())
        singleReservationTimeFrom = moment(tmpStart).add(
          "m",
          -defaultReservationsInterval
        )
        singleReservationTimeTo = moment(tmpStart).add(
          "m",
          productMinuteInterval - defaultReservationsInterval
        )
        continue
      }

      const dateDayPart = dayBreakpoints.find(e => {
        return start.getHours() >= e.breakpoint
      })

      if (!suggestedDates[dateDayPart.key]) {
        suggestedDates[dateDayPart.key] = []
      }

      suggestedDates[dateDayPart.key].push({ start, end, active: false })
    }
    setSuggestedAvailableDates(suggestedDates)
  }, [config, productMinuteInterval])

  const onReservationDateClick = date => {
    const availableDates = Object.assign({}, suggestedAvailableDates)
    const newDates = {}

    Object.entries(availableDates).forEach(e => {
      newDates[e[0]] = e[1].map(k => {
        k.active = false
        if (moment(k.start).isSame(date.start)) {
          k.active = true
        }
        return k
      })
    })
    setSuggestedAvailableDates(newDates)
  }
  return (
    <div>
      {suggestedAvailableDates && (
        <AvailableReservationsWrapper>
          {[...dayBreakpoints]
            .sort((a, b) => a.order - b.order)
            .map((e, i) => {
              return (
                <DayPartWrapper key={i}>
                  <DayPartName>{e.text}</DayPartName>
                  <DayPartHours>
                    {suggestedAvailableDates[e.key] &&
                      suggestedAvailableDates[e.key].map((k, j) => {
                        return (
                          <SingleHourWrapper
                            className={k.active ? "active" : ""}
                            onClick={() => onReservationDateClick(k)}
                            key={j}
                          >
                            {moment(k.start).format("HH:mm")} -{" "}
                            {moment(k.end).format("HH:mm")}
                          </SingleHourWrapper>
                        )
                      })}
                  </DayPartHours>
                </DayPartWrapper>
              )
            })}
        </AvailableReservationsWrapper>
      )}
    </div>
  )
}
