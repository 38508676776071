import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ChooseProductComponent from "./product/ChooseProductComponent"
import DateChooseComponent from "./date/DateChooseComponent"
import BookingStepsComponent from "./BookingStepsComponent"
import Salons from "../shared/Salons"
import { getObjects } from "../../services/api"
import MainButtonComponent from "../shared/MainButtonComponent"

const BookingWrapper = styled.div`
  padding-top: 120px;
`

export default function BookingProccessComponent({ state }) {
  console.log(state)
  const [selectedServices, setSelectedServices] = useState(null)
  const [servicesApproved, setServicesApproved] = useState(false)
  const [stepsConfig, setStepsConfig] = useState(false)
  const [availableProducts, setAvailableProducts] = useState(null)
  const [availableLocalizations, setAvailableLocalizations] = useState(null)
  const [activeStep, setActiveStep] = useState(null)
  const [bookingDetails, setBookingDetails] = useState({
    localization: null,
    services: [],
    worker: null,
  })

  const allowMultiselect = true

  useEffect(() => {
    const stepsConfig = [
      { id: 2, name: "usługa", active: false, filled: false, hide: false },
      { id: 3, name: "termin", active: false, filled: false, hide: false },
      {
        id: 4,
        name: "podsumowanie",
        active: false,
        filled: false,
        hide: false,
      },
    ]
    getObjects().then(res => {
      setAvailableLocalizations(res.data)

      if (res.data.length > 1) {
        stepsConfig.unshift({
          id: 1,
          name: "lokalizacja",
          active: false,
          filled: false,
          hide: false,
        })
      }
      stepsConfig[0].active = true
      setStepsConfig(stepsConfig)
    })
  }, [])

  useEffect(() => {
    if (!stepsConfig) return
    setActiveStep(stepsConfig.find(s => s.active).id)
  }, [stepsConfig])

  const onProductsChangeState = products => {
    setSelectedServices(products.filter(e => e.selected))
  }
  const onSalonClick = salon => {
    console.log(salon)
    const tmp = Object.assign({}, bookingDetails)
    tmp.localization = salon
    setBookingDetails(tmp)
  }
  const onItemClick = item => {
    console.log(item)
    const tmp = stepsConfig.map(e => {
      return { ...e, active: false }
    })
    tmp.find(e => e.id == item.id).active = true
    setStepsConfig(tmp)
  }
  const onNextStepClick = () => {
    const newActiveStep = activeStep + 1
    const tmp = stepsConfig.map(e => e)
    tmp.forEach(element => {
      element.active = false
      if (element.id <= newActiveStep) {
        element.filled = true
      }
      if (element.id == newActiveStep) {
        element.active = true
      }
      setStepsConfig(tmp)
    })
  }

  const onSaveObjects = objects => {
    setAvailableLocalizations(objects)
  }
  const onProductsDownload = e => {
    setAvailableProducts(e)
  }
  return (
    <BookingWrapper>
      <BookingStepsComponent
        onItemClick={onItemClick}
        stepsConfig={stepsConfig}
      />
      {activeStep == 1 && (
        <div>
          <Salons
            activeSalonId={
              bookingDetails.localization && bookingDetails.localization.id
            }
            onSalonClick={onSalonClick}
            sectionTitle={"Wybierz salon"}
            buttonText={"Wybierz"}
            availableSalons={availableLocalizations}
            saveObjects={onSaveObjects}
          />
          {bookingDetails.localization && (
            <div>
              <div>
                wybrano:
                {`${bookingDetails.localization.name}, ${bookingDetails.localization.address}`}
              </div>
              <div>
                <MainButtonComponent onClick={onNextStepClick} text="Dalej" />
              </div>
            </div>
          )}
        </div>
      )}
      {activeStep == 2 && (
        <div>
          <ChooseProductComponent
            availableProducts={availableProducts}
            onProductsDownload={onProductsDownload}
            onProductsChangeState={onProductsChangeState}
            allowMultiselect={allowMultiselect}
          ></ChooseProductComponent>
          {selectedServices && (
            <div>
              wybrane usługi:
              {selectedServices.map((service, serviceId) => {
                return <div key={serviceId}>{service.name}</div>
              })}
              <div>
                <MainButtonComponent onClick={onNextStepClick} text="Dalej" />
              </div>
            </div>
          )}
        </div>
      )}

      {activeStep == 3 && <DateChooseComponent products={selectedServices} />}
    </BookingWrapper>
  )
}
