import React from "react"
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useParams,
// } from "react-router-dom"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BookingOnObjectComponent from "../components/booking/BookingOnObjectComponent"
import BookingProccessComponent from "../components/booking/BookingProccessComponent"

const BookingPage = ({ location }) => (
  <Layout>
    <SEO title="HoOme" />
    {/* TODO: dynamic route w zaleznosci od wybranego salonu na stronie glownej:
    a) rezerwacja caly czas na tym samym roucie;
    b) kazdy salon na innym roucie rezerwacja/:idSalonu
    --> raczej a */}
    {/* <Router>
      <Route
        exact
        path="/rezerwacja/:id"
        render={props => {
          return <BookingOnObjectComponent id={props.match.params.id} />
        }}
      />
      <Route exact path="/rezerwacja" children={<BookingProccessComponent />} />
    </Router> */}
    <BookingProccessComponent state={location.state} />
  </Layout>
)

export default BookingPage
